<template>
  <v-container>
    <v-card class="mx-auto" tile>
      <v-card-title class="gradients white--text text-capitalize">
        <v-layout row wrap justify-space-between class="">
          <v-flex xs6 sm4 md4 lg4 class="">
            <v-btn
              text
              class="picked mx-4"
              dark
              small
              :loading="loading"
              elevation="3"
              @click="backToProfile()"
            >
              <v-icon small left>fas fa-arrow-left</v-icon>
              <span class="font-weight-bold">{{ $t("back") }}</span>
            </v-btn>
          </v-flex>
        </v-layout>
        <v-spacer></v-spacer>

        <v-btn
          text
          class="picked"
          dark
          small
          :loading="loading"
          elevation="3"
          @click="updateProfile()"
        >
          <v-icon small left>save</v-icon>
          <span class="font-weight-bold">{{ $t("save changes") }}</span>
        </v-btn>
      </v-card-title>

      <v-container class="px-7">
        <v-layout row wrap>
          <v-flex xs12 sm12 md3 lg3>
            <v-avatar class="profile" color="white" size="164" tile>
              <v-img :src="detail.avatar" contain></v-img>
            </v-avatar>

            <input type="file" @change="previewPicture" class="caption mt-2" />
          </v-flex>
          <v-flex xs12 sm12 md9 lg9>
            <v-layout row wrap class="mt-4">
              <v-flex xs12 sm4 md4 lg4 class="pa-1">
                <v-text-field
                  v-model="detail.names.first"
                  :color="$store.state.secondaryColor"
                  :label="$t('firstname')"
                  :readonly="editable == false"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm4 md4 lg4 class="pa-1">
                <v-text-field
                  v-model="detail.names.middle"
                  :color="$store.state.secondaryColor"
                  :label="$t('middlename')"
                  :readonly="editable == false"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm4 md4 lg4 class="pa-1">
                <v-text-field
                  v-model="detail.names.last"
                  :color="$store.state.secondaryColor"
                  :label="$t('lastname')"
                  :readonly="editable == false"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-flex>
            </v-layout>

            <v-layout row wrap class="mt-4">
              <v-flex xs12 sm4 md4 lg4 class="pa-1">
                <v-radio-group
                  :label="$t('gender')"
                  :color="$store.state.secondaryColor"
                  v-model="detail.gender"
                  :readonly="editable == false"
                  row
                >
                  <v-radio value="M">
                    <template v-slot:label>
                      <div>M</div>
                    </template>
                  </v-radio>

                  <v-radio value="F">
                    <template v-slot:label>
                      <div>F</div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-flex>
              <v-flex xs12 sm4 md4 lg4 class="pa-1">
                <v-text-field
                  v-model="detail.email"
                  :color="$store.state.secondaryColor"
                  :label="$t('email')"
                  :readonly="editable == false"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm4 md4 lg4 class="pa-1">
                <v-text-field
                  v-model="detail.names.last"
                  :color="$store.state.secondaryColor"
                  :label="$t(lastname)"
                  :readonly="editable == false"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-flex>
            </v-layout>

            <v-layout row wrap class="mt-4">
              <v-flex xs12 sm4 md4 lg4 class="pa-1">
                <v-text-field
                  v-model="detail.address.pobox"
                  :color="$store.state.secondaryColor"
                  :label="$t('PO BOX')"
                  :readonly="editable == false"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm4 md4 lg4 class="pa-1">
                <v-text-field
                  v-model="detail.address.country"
                  :color="$store.state.secondaryColor"
                  :label="$t('country')"
                  :readonly="editable == false"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm4 md4 lg4 class="pa-1">
                <v-text-field
                  v-model="detail.address.county"
                  :color="$store.state.secondaryColor"
                  :label="$t('county')"
                  :readonly="editable == false"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-flex>
            </v-layout>

            <v-layout row wrap class="mt-4">
              <v-flex xs12 sm4 md4 lg4 class="pa-1">
                <v-text-field
                  v-model="detail.address.city"
                  :color="$store.state.secondaryColor"
                  :label="$t('city')"
                  :readonly="editable == false"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm4 md4 lg4 class="pa-1">
                <v-text-field
                  v-model="detail.address.street"
                  :color="$store.state.secondaryColor"
                  :label="$t('street')"
                  :readonly="editable == false"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm4 md4 lg4 class="pa-1">
                <v-text-field
                  v-model="detail.address.estate"
                  :color="$store.state.secondaryColor"
                  :label="$t('estate')"
                  :readonly="editable == false"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-flex>
            </v-layout>

            <v-layout row wrap class="mt-4">
              <v-flex xs12 sm4 md4 lg4 class="pa-1">
                <v-text-field
                  v-model="detail.address.building"
                  :color="$store.state.secondaryColor"
                  :label="$t('building')"
                  :readonly="editable == false"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm4 md4 lg4 class="pa-1">
                <v-text-field
                  v-model="detail.address.houseNo"
                  :color="$store.state.secondaryColor"
                  :label="$t('house-suite-number')"
                  :readonly="editable == false"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>

    <v-snackbar
      v-model="snackSuccess"
      top
      center
      color="success lighten-5 success--text text--darken-3"
      :timeout="4000"
    >
      <span> {{ $t("data updated successfully") }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="success darken-3"
          @click="snackSuccess = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="snackPermission"
      top
      center
      color="warning lighten-5 warning--text text--darken-3"
      :timeout="4000"
    >
      <span
        >{{
          $t(
            "your access level only allows you to update your profile picture"
          )
        }}.</span
      >
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="warning darken-3"
          @click="snackPermission = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="dialog" max-width="400" persistent>
      <v-card dark height="30">
        <v-card-text class="py-2">
          <v-progress-linear
            height="10"
            :v-model="progressValue"
            :buffer-value="progressValue"
            stream
            striped
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import jwtdecode from "jwt-decode";
import fb from "firebase/app";
import db from "@/plugins/fb";
import jwt from "jsonwebtoken";
export default {
  data: () => ({
    user: {
      names: {
        first: "",
        middle: "",
        last: "",
      },
      avatar: "",
      email: "",
      gender: "",
      phone: "",
      address: {
        building: "",
        city: "",
        country: "",
        county: "",
        estate: "",
        houseNo: "",
        pobox: "",
        street: "",
      },

      dateCreated: new Date(),
    },
    editable: "",
    snackPermission: false,

    file: "",
    detail: "",
    dialog: false,
    loading: false,
    progressValue: 10,
    snackSuccess: false,
    imagePreview: "https://cdn.vuetifyjs.com/images/profiles/marcus.jpg",
  }),

  created() {
    this.detail = jwtdecode(
      this.$store.state.token,
      this.$store.state.accessKey
    );

    this.getProfile();
  },

  methods: {
    previewPicture(event) {
      this.file = event.target.files[0];
      this.detail.avatar = URL.createObjectURL(this.file);
    },

    getProfile() {
      //Determine user types
      if (this.detail.accessLevel.isOwner) {
        //make the field editable if the user is owner
        this.editable = true;
      } else {
        this.snackPermission = true;

        //Disable fields if user is employee or manager
        this.editable = false;
      }
    },

    updateProfile() {
      if (this.detail.accessLevel.isOwner) {
        //if no changes are made on the file, skip
        if (this.file != "") {
          this.dialog = true;
          this.progressValue = 30;

          // ####### SET UPDATED USER DATA #######

          this.user.names.first = this.detail.names.first;
          this.user.names.middle = this.detail.names.middle;
          this.user.names.last = this.detail.names.last;
          this.user.avatar = this.detail.avatar;
          this.user.email = this.detail.email;
          this.user.gender = this.detail.gender;

          this.user.phone = this.detail.phone;
          this.user.address.building = this.detail.address.building;
          this.user.address.city = this.detail.address.city;
          this.user.address.country = this.detail.address.country;
          this.user.address.county = this.detail.address.county;
          this.user.address.estate = this.detail.address.estate;
          this.user.address.houseNo = this.detail.address.houseNo;
          this.user.address.pobox = this.detail.address.pobox;
          this.user.address.street = this.detail.address.street;

          db.collection("owners")
            .doc(this.detail.accessLevel.username)
            .update(this.user)
            .then(() => {
              //allow the owner to update their picture
              this.uploadProfilePictureOwner();
            })
            .catch((error) => {
              this.dialog = false;
              console.error("Error updating document: ", error);
            });
        } else {
          this.dialog = true;
          this.progressValue = 30;

          // ####### SET UPDATED USER DATA #######

          this.user.names.first = this.detail.names.first;
          this.user.names.middle = this.detail.names.middle;
          this.user.names.last = this.detail.names.last;
          this.user.avatar = this.detail.avatar;
          this.user.email = this.detail.email;
          this.user.gender = this.detail.gender;

          this.user.phone = this.detail.phone;
          this.user.address.building = this.detail.address.building;
          this.user.address.city = this.detail.address.city;
          this.user.address.country = this.detail.address.country;
          this.user.address.county = this.detail.address.county;
          this.user.address.estate = this.detail.address.estate;
          this.user.address.houseNo = this.detail.address.houseNo;
          this.user.address.pobox = this.detail.address.pobox;
          this.user.address.street = this.detail.address.street;

          db.collection("owners")
            .doc(this.detail.accessLevel.username)
            .update(this.user)
            .then(() => {
              this.progressValue = 50;
              this.progressValue = 70;
              this.progressValue = 100;

              this.dialog = false;

              this.snackSuccess = true;

              //set new jwt token data
              let key = this.$store.state.accessKey;
              let token = jwt.sign(this.detail, key);

              //keep token in store
              this.$store.commit("SET_TOKEN", token);
            })
            .catch((error) => {
              this.dialog = false;
              console.error("Error updating document: ", error);
            });
        }
      } else if (this.detail.accessLevel.isEmployee) {
        if (this.file != "") {
          this.dialog = true;
          //set new jwt token data
          let key = this.$store.state.accessKey;
          let token = jwt.sign(this.detail, key);

          //keep token in store
          this.$store.commit("SET_TOKEN", token);

          let storageRef = fb
            .storage()
            .ref("employees/" + this.detail.accessLevel.username);
          let uploadTask = storageRef.put(this.file);

          this.progressValue = 50;
          this.progressValue = 70;

          this.progressValue = 100;

          uploadTask.on(
            "state_changed",
            () => {},
            (error) => {
              error.preventDefault();
            },
            () => {
              uploadTask.snapshot.ref.getDownloadURL().then((downloadLink) => {
                db.collection("employees")
                  .doc(this.detail.accessLevel.username)
                  .update({
                    avatar: downloadLink,
                  })
                  .then(() => {
                    this.loading = false;
                    this.progressValue = 100;

                    this.dialog = false;
                    this.snackSuccess = true;
                  });
              });
            }
          );
        }
      } else if (this.detail.accessLevel.isManager) {
        if (this.file != "") {
          this.dialog = true;
          //set new jwt token data
          let key = this.$store.state.accessKey;
          let token = jwt.sign(this.detail, key);

          //keep token in store
          this.$store.commit("SET_TOKEN", token);

          //store images
          let storageRef = fb
            .storage()
            .ref("employees/" + this.detail.accessLevel.username);
          let uploadTask = storageRef.put(this.file);

          this.progressValue = 50;
          this.progressValue = 70;

          this.progressValue = 100;

          uploadTask.on(
            "state_changed",
            () => {},
            (error) => {
              error.preventDefault();
            },
            () => {
              uploadTask.snapshot.ref.getDownloadURL().then((downloadLink) => {
                db.collection("employees")
                  .doc(this.detail.accessLevel.username)
                  .update({
                    avatar: downloadLink,
                  })
                  .then(() => {
                    this.loading = false;
                    this.progressValue = 100;
                    this.dialog = false;
                    this.snackSuccess = true;
                  });
              });
            }
          );
        }
      }
    },
    uploadProfilePictureOwner() {
      this.progressValue = 50;
      this.progressValue = 70;

      let uploadTask = fb
        .storage()
        .ref("users/" + this.detail.accessLevel.username)
        .put(this.file);

      uploadTask.on(
        "state_changed",
        () => {},
        () => {},
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadLink) => {
            db.collection("owners")
              .doc(this.$route.params.id)
              .update({
                avatar: downloadLink,
              })
              .then(() => {
                //set new jwt token data
                let key = this.$store.state.accessKey;
                let token = jwt.sign(this.detail, key);

                console.log("token");
                console.log(token);
                //keep token in store
                this.$store.commit("SET_TOKEN", token);

                this.loading = false;
                this.progressValue = 100;
                this.dialog = false;
                this.snackSuccess = true;
              })
              .catch((error) => {
                console.error("Error updating document: ", error);
              });
          });
        }
      );
    },

    backToProfile() {
      this.$router.push("/profile");
    },
  },
};
</script>

<style></style>
